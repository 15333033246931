import { getBaseQuery } from 'connectors/sp';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ProvidersQuery,
  ProvidersQueryVariables,
  ProvidersDocument,
  ProviderDocument,
  ProviderQuery,
  ProviderQueryVariables,
} from 'generated/schemas/providers';

export const providers = createApi({
  reducerPath: 'providers',
  baseQuery: getBaseQuery(),
  endpoints: (build) => ({
    getProviders: build.query<ProvidersQuery, ProvidersQueryVariables>({
      query: (variables) => ({ document: ProvidersDocument, variables }),
    }),
    getProvider: build.query<ProviderQuery, ProviderQueryVariables>({
      query: (variables) => ({ document: ProviderDocument, variables }),
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useLazyGetProvidersQuery,
  useGetProviderQuery,
  useLazyGetProviderQuery,
} = providers;
