import Web3 from 'web3';
import {
  OrderStatus, OrderInfo, OrderSlots, OfferType, ParamName, StorageAccess, TokenAmount, BlockInfo, constants,
} from '@super-protocol/sdk-js';
import {
  Encoding,
  CryptoAlgorithm,
  Encryption,
  StorageProviderResource,
  TeeOrderEncryptedArgs,
  RuntimeInputInfo,
  RIIType,
  Hash,
} from '@super-protocol/dto-js';
import { BigNumber } from 'bignumber.js';
import getConfig from 'config';
import { getExternalId, sleep } from 'utils';
import { Slots } from 'lib/features/createOrderV2/types';
import { PCCS_API } from 'connectors/urls';

export interface WorkflowPropsValuesOffer {
  value?: string;
  slots?: Slots | null;
}

export interface ApproveWorkflowProps {
  address: string;
  web3: Web3;
}

export type FileResource = Partial<RuntimeInputInfo> & { type: RIIType; }

export interface PrepareWorkflowProps {
  solution?: WorkflowPropsValuesOffer[];
  data?: WorkflowPropsValuesOffer[];
  tee?: WorkflowPropsValuesOffer;
  storage?: WorkflowPropsValuesOffer;
  deposit: string; // wei
  privateKey: string;
  encryptedArgs?: string;
  teeExternalId: string;
  filesResources?: FileResource[];
  argsHash: Hash;
}

export interface PrepareWorkflowResult {
  parentOrderInfo: OrderInfo;
  parentOrderSlot: OrderSlots;
  subOrdersInfo: OrderInfo[];
  subOrdersSlots: OrderSlots[];
  workflowDeposit: TokenAmount;
  privateKey: string;
}

export interface WorkflowProps {
  web3: Web3;
  address: string;
  values: PrepareWorkflowResult;
}

export interface WorkflowResult {
  workflowCreationBlockIndex: number;
  txHash: string;
}

export interface GetWorkflowParentOrderProps {
  offer: string;
  encryptedArgs?: string;
  inputOffers?: string[];
  outputOffer?: string;
  algo: CryptoAlgorithm;
  privateKey: string;
  externalId: string;
  filesResources?: FileResource[];
  argsHash: Hash;
}

export interface GetWorkflowSubOrderProps {
  offer: string;
  externalId: string;
  inputOffers?: string[];
  outputOffer?: string;
}

export interface ReplenishOrderProps {
  orderId?: string;
  amount?: string;
  instance?: Web3;
  address?: string;
}

export interface CancelOrderProps {
  orderId?: string;
  instance?: Web3;
  accountAddress?: string;
}

export interface ApproveValuesProps {
  deposit: string; // wei
}
export interface ApproveProps {
  values: ApproveValuesProps;
  address: string;
  web3: Web3;
}

export interface GenerateByOfferProps {
  offerId: string;
  inputOffers: string[];
  resource: StorageProviderResource;
  encryption: Encryption;
  fileResource: FileResource;
}

export type GenerateByOfferMultipleProps = GenerateByOfferProps[];

export interface UploadArgsToStorjProps {
  teeOfferId: string;
  args: TeeOrderEncryptedArgs;
  key: string;
  access: {
    read: StorageAccess;
    write: StorageAccess;
  };
}

export interface EncryptByTeeBlockProps {
  teeOfferId: string;
  configuration: string;
}

export interface GetOrderIdProps {
  externalId: string;
  fromBlock: number;
  address: string;
}

export interface GetOrderIdByExternalIdProps {
  externalId: string;
  workflowCreationBlockIndex: number;
  address: string;
  interval?: number;
}

export interface GenerateRiiProps {
  inputOffers: string[];
  filesResources: FileResource[];
}

export default class BlockchainConnector {
  private static instance: BlockchainConnector;
  private initialized = false;
  private initializationPromise: Promise<BlockchainConnector> | null = null;

  private constructor() {}

  public static getInstance(): BlockchainConnector {
    if (!BlockchainConnector.instance) {
      BlockchainConnector.instance = new BlockchainConnector();
    }

    return BlockchainConnector.instance;
  }

  public isInitialized() {
    return this.initialized;
  }

  public checkIfInitialized(): void {
    if (!this.initialized) {
      throw new Error(
        `${this.constructor.name} is not initialized, needs to run '${this.constructor.name}.initialize(CONFIG)' first`,
      );
    }
  }

  public async initialize(): Promise<BlockchainConnector> {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      if (this.isInitialized()) return BlockchainConnector.getInstance();

      const {
        NEXT_PUBLIC_BLOCKCHAIN_API,
        NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS,
      } = getConfig();

      if (!NEXT_PUBLIC_BLOCKCHAIN_API) {
        throw new Error('BlockchainUrl is not defined');
      }
      if (!NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS) {
        throw new Error('ContractAddress is undefined');
      }

      const { BlockchainConnector: BlockchainConnectorSDK } = await import('@super-protocol/sdk-js');
      const instance = BlockchainConnectorSDK.getInstance();
      await instance.initialize({
        blockchainUrl: NEXT_PUBLIC_BLOCKCHAIN_API,
        contractAddress: NEXT_PUBLIC_SP_MAIN_CONTRACT_ADDRESS,
        externalTransactionSigner: true,
      });

      this.initialized = true;
      return BlockchainConnector.getInstance();
    })();

    return this.initializationPromise;
  }

  public async teeBalanceOf(address: string): Promise<string> {
    await this.initialize();
    const { SuperproToken } = (await import('@super-protocol/sdk-js'));
    return SuperproToken.balanceOf(address);
  }

  public async balanceOf(address: string): Promise<string> {
    await this.initialize();
    const { BlockchainConnector: BlockchainConnectorSDK } = (await import('@super-protocol/sdk-js'));
    return BlockchainConnectorSDK.getInstance().getBalance(address);
  }

  public static prepareSlots(slots?: Slots | null): OrderSlots {
    const { slot, options = [] } = slots || {};
    const { optionsIds = [], optionsCount = [] } = (options || []).reduce((acc, { id, count }) => {
      return {
        ...acc,
        optionsIds: [...acc.optionsIds, id],
        optionsCount: [...acc.optionsCount, count],
      };
    }, {
      optionsIds: [] as string[],
      optionsCount: [] as number[],
    }) || {};
    return {
      slotCount: slot?.count ?? 0,
      slotId: slot?.id ?? '',
      optionsIds,
      optionsCount,
    };
  }

  public async getWorkflowSubOrder(props: GetWorkflowSubOrderProps): Promise<OrderInfo> {
    await this.initialize();

    const {
      offer,
      outputOffer = '',
      inputOffers = [],
      externalId,
    } = props || {};

    if (!offer) {
      throw new Error('Offer id required');
    }

    if (!externalId) {
      throw new Error('External id required');
    }

    const inputOffersVersions: number[] = inputOffers.map(() => 0);

    return {
      offerId: offer,
      encryptedArgs: '',
      offerVersion: 0,
      resultInfo: {
        publicKey: '',
        encryptedInfo: '',
      },
      status: OrderStatus.New,
      args: {
        inputOffersIds: inputOffers,
        outputOfferId: outputOffer,
        inputOffersVersions,
        outputOfferVersion: 0,
      },
      externalId,
    };
  }

  public async calculateObjectHash(obj: any): Promise<Hash> {
    await this.initialize();
    const {
      helpers,
    } = await import('@super-protocol/sdk-js');

    return helpers.calculateObjectHash(obj);
  }

  public async encryptByTeeBlock(props: EncryptByTeeBlockProps): Promise<Encryption> {
    await this.initialize();
    const { teeOfferId, configuration } = props;

    const {
      RIGenerator,
    } = await import('@super-protocol/sdk-js');

    return RIGenerator.encryptByTeeBlock(
      teeOfferId,
      configuration,
      PCCS_API,
    );
  }

  public async uploadArgsToStorj(props: UploadArgsToStorjProps): Promise<StorageProviderResource> {
    await this.initialize();
    const {
      teeOfferId, args, key, access,
    } = props;
    const { helpers, TeeOffer } = await import('@super-protocol/sdk-js');
    const offerInfo = await new TeeOffer(teeOfferId).getInfo();
    const encryption: Encryption | null = offerInfo?.argsPublicKey ? JSON.parse(offerInfo.argsPublicKey) : null;
    if (!encryption) throw new Error('Encryption required for args');
    return helpers.OrderArgsHelper.uploadToStorage({
      args,
      key,
      access,
      encryption,
    });
  }

  public async isMoreThanGivenSize(args: Record<string, unknown>, sizeInBytes?: number) {
    await this.initialize();
    const { helpers } = await import('@super-protocol/sdk-js');
    return helpers.OrderArgsHelper.isMoreThanGivenSize(args, sizeInBytes);
  }

  public async encryptOrderArgs(args: unknown, teeOfferId: string) {
    await this.initialize();
    const { helpers, TeeOffer } = await import('@super-protocol/sdk-js');
    const offerInfo = await new TeeOffer(teeOfferId).getInfo();
    const encryption: Encryption | null = offerInfo?.argsPublicKey ? JSON.parse(offerInfo.argsPublicKey) : null;
    if (!encryption) throw new Error('Encryption required for encrypt order args');
    return helpers.OrderArgsHelper.encryptOrderArgs(args, encryption);
  }

  public async getWorkflowParentOrder(props: GetWorkflowParentOrderProps): Promise<OrderInfo> {
    await this.initialize();

    const {
      offer,
      encryptedArgs = '',
      algo,
      inputOffers = [],
      outputOffer = '',
      externalId,
      privateKey,
      filesResources = [],
      argsHash,
    } = props || {};

    if (!algo) {
      throw Error('Encryption algo required');
    }

    if (algo !== CryptoAlgorithm.ECIES) {
      throw Error('Only ECIES result encryption is supported');
    }

    if (!offer) {
      throw new Error('Offer id required');
    }

    if (!privateKey) {
      throw new Error('Private key required');
    }

    if (!externalId) {
      throw new Error('External id required');
    }

    const {
      RIGenerator,
    } = await import('@super-protocol/sdk-js');

    const runtimeInputInfos = await this.generateRii({ inputOffers, filesResources });

    const orderResultKeys = await RIGenerator.generate({
      offerId: offer,
      encryptionPrivateKey: {
        algo,
        encoding: Encoding.base64,
        key: privateKey,
      },
      pccsServiceApiUrl: PCCS_API,
      runtimeInputInfos,
      argsHash,
    });

    const inputOffersVersions: number[] = inputOffers.map(() => 0);

    return {
      offerId: offer,
      encryptedArgs,
      offerVersion: 0,
      resultInfo: orderResultKeys,
      status: OrderStatus.New,
      args: {
        inputOffersIds: inputOffers,
        outputOfferId: outputOffer,
        inputOffersVersions,
        outputOfferVersion: 0,
      },
      externalId,
    };
  }

  public async prepareWorkflow(props: PrepareWorkflowProps): Promise<PrepareWorkflowResult> {
    await this.initialize();

    const {
      tee,
      storage,
      data,
      solution,
      deposit,
      privateKey,
      encryptedArgs,
      teeExternalId,
      filesResources,
      argsHash,
    } = props || {};

    const { value: teeOffer, slots } = tee || {};

    if (!privateKey) throw new Error('Private key required');
    if (!teeOffer) throw new Error('Tee offer required');
    if (!slots?.slot?.id) throw new Error('Slots required');

    const parentOrderInfo = await this.getWorkflowParentOrder({
      encryptedArgs,
      privateKey,
      offer: teeOffer as string,
      algo: CryptoAlgorithm.ECIES,
      inputOffers: (data || []).concat(solution || []).map(({ value }) => value as string),
      outputOffer: storage?.value,
      externalId: teeExternalId,
      filesResources,
      argsHash,
    });

    const subOrders = (solution || []).concat(data || []);

    const subOrdersInfo = await Promise.all(
      subOrders
        .map(async ({ value }) => {
          return this.getWorkflowSubOrder({
            offer: value as string,
            outputOffer: storage?.value,
            externalId: getExternalId(),
          });
        }),
    );

    return {
      parentOrderInfo,
      parentOrderSlot: BlockchainConnector.prepareSlots(slots),
      subOrdersInfo,
      subOrdersSlots: subOrders.map(({ slots }) => BlockchainConnector.prepareSlots(slots)),
      workflowDeposit: deposit,
      privateKey,
    };
  }

  public async workflow(props: WorkflowProps): Promise<WorkflowResult> {
    await this.initialize();

    const { web3, address, values } = props;
    if (!address) throw new Error('Action account address required');

    const {
      parentOrderInfo, parentOrderSlot, subOrdersInfo, subOrdersSlots, workflowDeposit,
    } = values;

    const { Orders } = await import('@super-protocol/sdk-js');

    const workflowCreationBlock = await this.getLastBlockInfo();

    const receipt = await Orders.createWorkflow(
      parentOrderInfo,
      parentOrderSlot,
      subOrdersInfo,
      subOrdersSlots,
      workflowDeposit,
      { from: address, web3: web3 as any },
      false,
    );

    return {
      workflowCreationBlockIndex: workflowCreationBlock.index,
      txHash: Buffer.from(receipt.transactionHash).toString('hex'),
    };
  }

  public async approve(props: ApproveProps): Promise<void> {
    await this.initialize();
    const { values, address, web3 } = props || {};
    const { deposit } = values || {};
    const { Orders, SuperproToken } = await import('@super-protocol/sdk-js');
    const Web3 = (await import('web3')).default;
    const value = await SuperproToken.allowance(address, Orders.address);
    if (new BigNumber(value.toString()).isLessThan(new BigNumber(deposit))) {
      await SuperproToken.approve(
        Orders.address,
        Web3.utils.toWei(new BigNumber(1e10).toString(), 'ether'),
        { from: address, web3: web3 as any }, // todo update web3 package
      );
    }
  }

  public async replenishOrder({
    orderId,
    amount,
    instance,
    address,
  }: ReplenishOrderProps): Promise<void> {
    await this.initialize();
    if (!orderId) throw new Error('Order id required');
    if (!address) throw new Error('Account address required');
    if (!instance) throw new Error('Web3 instance required');
    if (!amount) throw new Error('Amount required');

    const { Orders } = await import('@super-protocol/sdk-js');

    await this.approve({
      address,
      values: { deposit: amount },
      web3: instance,
    });
    await Orders.refillOrderDeposit(
      orderId,
      amount,
      { from: address, web3: instance as any }, // todo update web3 package
    );
  }

  public async cancelOrder({
    orderId,
    instance,
    accountAddress,
  }: CancelOrderProps): Promise<void> {
    if (!orderId) throw new Error('Order id required');
    if (!accountAddress) throw new Error('Account address required');
    if (!instance) throw new Error('Web3 instance required');
    await this.initialize();

    const { Orders } = await import('@super-protocol/sdk-js');
    await Orders.cancelWorkflow(
      orderId,
      { from: accountAddress, web3: instance as any },
    );
  }

  public async getAllowance(actionAccountAddress: string): Promise<string> {
    await this.initialize();
    const { Orders, SuperproToken } = await import('@super-protocol/sdk-js');
    return (
      await SuperproToken.allowance(actionAccountAddress, Orders.address)
    ).toString();
  }

  public async isAllowGood(address: string, deposit: string): Promise<boolean> {
    const allow = await BlockchainConnector.getInstance().getAllowance(address);
    if (new BigNumber(allow).isLessThan(new BigNumber(deposit))) {
      return false;
    }
    return true;
  }

  public async getOfferHoldSum(offerId: string, slots?: Slots): Promise<string> {
    await this.initialize();
    if (!offerId || !slots?.slot?.id) return '0';
    const { TeeOffer, Offer } = await import('@super-protocol/sdk-js');
    const offerInstance = new TeeOffer(offerId);
    const offerType = await offerInstance.getOfferType();
    const result = offerType === OfferType.TeeOffer
      ? await new TeeOffer(offerId).getMinDeposit(slots.slot.id, slots.slot.count, [], [])
      : await new Offer(offerId).getMinDeposit(slots.slot.id);
    return result.toString();
  }

  public async getOrderMinDeposit(): Promise<string> {
    await this.initialize();
    const { Superpro } = (await import('@super-protocol/sdk-js'));
    return (await Superpro.getParam(ParamName.OrderMinimumDeposit)).toString();
  }

  public async approveWorkflow(props: ApproveWorkflowProps): Promise<void> {
    await this.initialize();
    const { address, web3 } = props || {};
    const { Orders, SuperproToken } = await import('@super-protocol/sdk-js');
    const Web3 = (await import('web3')).default;
    await SuperproToken.approve(
      Orders.address,
      Web3.utils.toWei(new BigNumber(1e10).toString(), 'ether'),
      { from: address, web3: web3 as any }, // todo update web3 package
    );
  }

  public async shutdown(): Promise<void> {
    if (this.initialized) {
      this.initialized = false;
      const { BlockchainConnector: BlockchainConnectorSDK } = (await import('@super-protocol/sdk-js'));
      BlockchainConnectorSDK.getInstance().shutdown();
    }
  }

  public async generateRii({ inputOffers, filesResources }: GenerateRiiProps) {
    await this.initialize();
    const { TIIGenerator } = (await import('@super-protocol/sdk-js'));
    const runtimeInputInfos = await TIIGenerator.generateRiiByOfferIds(inputOffers);

    const buildRuntimeInputInfo = (fileResource: FileResource): RuntimeInputInfo => {
      const { args, hash, type } = fileResource;
      return {
        args,
        hash: hash ?? constants.ZERO_HASH,
        type,
      };
    };

    if (filesResources?.length) {
      filesResources.forEach((fileResource) => {
        runtimeInputInfos.push(buildRuntimeInputInfo(fileResource));
      });
    }

    return runtimeInputInfos;
  }

  public async generateByOffer(props: GenerateByOfferProps): Promise<string> {
    await this.initialize();
    const { TIIGenerator } = (await import('@super-protocol/sdk-js'));

    const {
      offerId,
      inputOffers,
      encryption,
      resource,
      fileResource,
    } = props;

    const runtimeInputInfos = await this.generateRii({ inputOffers, filesResources: fileResource ? [fileResource] : [] });

    return TIIGenerator.generateByOffer({
      offerId,
      resource,
      args: undefined,
      encryption,
      sgxApiUrl: PCCS_API,
      runtimeInputInfos,
    });
  }

  public async generateByOfferMultiple(list: GenerateByOfferMultipleProps): Promise<string[]> {
    return Promise.all(list.map(this.generateByOffer.bind(this)));
  }

  public async getOrderId(params: GetOrderIdProps): Promise<string | null> {
    await this.initialize();
    const { externalId, fromBlock, address } = params;
    const { Orders } = await import('@super-protocol/sdk-js');

    const event = await Orders.getByExternalId(
      { externalId, consumer: address },
      fromBlock,
    );
    if (event && event.orderId !== '-1') {
      return event.orderId;
    }

    return null;
  }

  public async getOrderIdByExternalId(params: GetOrderIdByExternalIdProps): Promise<string> {
    await this.initialize();
    const SLEEP_TIME = 3000;
    const MAX_ATTEMPS = 3;
    let attempt = 0;

    const {
      externalId, workflowCreationBlockIndex, address, interval = SLEEP_TIME,
    } = params;

    do {
      const orderId = await this.getOrderId({
        externalId,
        fromBlock: workflowCreationBlockIndex,
        address,
      });
      if (orderId) {
        return orderId;
      }

      await sleep(interval);
      attempt++;
    } while (attempt < MAX_ATTEMPS);

    throw new Error(`Could not find order id (externalId=${externalId})`);
  }

  public async getLastBlockInfo(): Promise<BlockInfo> {
    await this.initialize();
    const { BlockchainConnector: BlockchainConnectorSDK } = (await import('@super-protocol/sdk-js'));
    return BlockchainConnectorSDK.getInstance().getLastBlockInfo();
  }

  public async calculateTotalDepositSpent(orderId: string): Promise<string> {
    await this.initialize();
    const { Order } = await import('@super-protocol/sdk-js');
    const order = new Order(orderId);
    const totalDepositSpent = await order.calculateTotalDepositSpent();
    return totalDepositSpent;
  }

  public async calculateTotalDepositUnspent(orderId: string): Promise<string> {
    await this.initialize();
    const { Order } = await import('@super-protocol/sdk-js');
    const order = new Order(orderId);
    const totalDepositUnspent = await order.calculateTotalDepositUnspent();
    return totalDepositUnspent;
  }

  public async calculateCurrentPrice(orderId: string): Promise<string> {
    await this.initialize();
    const { Order } = await import('@super-protocol/sdk-js');
    const order = new Order(orderId);
    const currentPrice = await order.calculateCurrentPrice();
    return currentPrice;
  }
}
