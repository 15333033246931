import { createHash, BinaryLike } from 'crypto';
import {
  HashAlgorithm, Hash, Encoding,
} from '@super-protocol/dto-js';

export interface HasherProps {
  algo: HashAlgorithm;
  encoding: Encoding,
}

export class Hasher {
  private hash: ReturnType<typeof createHash>;
  private encoding: Encoding;
  private algo: HashAlgorithm;

  constructor(props: HasherProps) {
    const { encoding, algo } = props;
    this.encoding = encoding;
    this.algo = algo;
    this.hash = createHash(algo);
  }

  update(data: Buffer | string): Hasher {
    this.hash.update((data instanceof Buffer ? data : Buffer.from(data)) as BinaryLike);
    return this;
  }

  finalize(): Hash {
    return {
      algo: this.algo,
      hash: this.hash.digest().toString(this.encoding),
      encoding: this.encoding,
    };
  }

  updateStream(
    inputStream: ReadableStream<Uint8Array>,
  ): { stream: ReadableStream<Uint8Array>; getHash: () => Hash; } {
    const transformStream = new TransformStream<Uint8Array, Uint8Array>({
      transform: async (chunk, controller) => {
        try {
          this.hash.update(chunk);
          controller.enqueue(chunk);
        } catch (error) {
          console.error('Stream hashing error: ', error);
          controller.terminate();
        }
      },
    });

    const getHash = () => {
      return this.finalize();
    };

    return { stream: inputStream.pipeThrough(transformStream), getHash };
  }
}
